section[data-section='login'] {
  .column-image {
    @include mobile {
      display: none;
    }
    background-position: center center;
    background-size: cover;
    height: 100vh;
  }

  .title-login {
    text-transform: uppercase;
    color: white;
    font-size: 25px;
    font-weight: 600;
    display: block;
    text-align: center;
    padding-bottom: 10px;
    
    @include mobile {
      font-size: 6vw;
    }
  }

  .column-form {
    height: 100vh;
    padding: 0;
    margin: 0;

    .column-card {
      padding: 0 60px;
      @include mobile {
        padding: 0 20px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        .card-body {
          padding: 10px;
        }
      }
    }
  }
}