#navbarSite {
  height: 100vh;
  position: fixed;
  left: 0;
  width: 16%;
  background: white;

  .data-user-sidebar{
    ul{
      padding-top: 10px;
      li{
        color: #444444;
        font-size: 12px;
        line-height: 1.3;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .is-not-active {
    height: 0;
    padding: 0;
    overflow: hidden;
    transition: all .3s ease-in;
  }

  .is-active {
    height: unset;
    transition: all .8s ease-in;
  }

  .menu-label {
    > a {
      display: block;
      font-size: 14px;
      text-transform: uppercase;
      color: $cyan;
      transition: all .4s ease;
      padding: 2px 0;

      i {
        //border:1px solid red;
        display: inline-block;
        text-align: center;
        width: 18px;
        //color: red !important;
      }

      &:hover {
        color: $color_fondo;
        font-weight: 500;
      }

      &.active {
        color: $color_fondo;
        padding-bottom: 5px;
        border-bottom: 1px solid $color_fondo;
        //border-radius: 4px;
        //padding: 7px 5px;
        //background-color: $color_fondo;
      }
    }

  }

  ul.menu-list {
    transition: all 3s ease;

    li {
      a {
        font-size: 15px;
        color: #4c4c4c;
        transition: all .4s ease-in;
        line-height: 1;
        display: block;
        padding: 10px 5px;
        border-radius: 3px;

        &:hover {
          background-color: $color_fondo;
          color: white;
        }
      }
    }
  }
}