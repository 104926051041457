html {
  background: $color_fondo !important;
}

figure {
  line-height: 0;
  margin: 0;
  padding: 0;
}

.notifications-widget {
  padding: 0;
  margin: 0;
  border-radius: 5px;
  right: 0;
  top: 0;
  width: 100%;
  text-align: left;

  .row {
    width: 25%;
  }

  a {
    display: block;
    transform: translateY(-2px);
  }
}

body {
  background: $color_fondo !important;
  margin-top: 0;
  overflow-x: hidden;
  font-family: $font;


  label {
    line-height: 0.5;
  }

  .bootstrap-select {
    > button {
      border-radius: 0;
      border: 1px solid lightgrey !important;
    }
  }

  h1, h2, h3, h4, h5, h6, a, p, table, td, th, tr, button, span, input, textarea, select {
    font-family: $font !important;
  }

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  table {
    font-size: 12.5px;
  }

  &.no-margin {
    margin-top: 0;
  }

  a, button {

    &:hover {
      text-decoration: none !important;
    }
  }

  form {
    border-radius: 5px;
    background-color: white;
    padding: 15px;

    &.form-simple {
      padding: 0;
      background: transparent;
    }
  }


  table {
    ul {
      padding: 0 !important;
      margin: 0;

      li {
        padding: 0;
      }
    }
  }


  .layout-container {
    padding: 10px;
    background: #f6f6f6;
  }

  select, select.form-control {
    font-size: 14px !important;
  }

  //.dropdown:hover .dropdown-menu {
  //  display: block;
  //  margin-top: 0; // remove the gap so it doesn't close
  //}


  table {
    .dropdown {
      display: inline-block;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

  }
}

#data_pago {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 80%;
  max-height: 80%;
  height: auto;
  padding: 20px;
  box-shadow: 0 0 2px grey;
  display: none;
  overflow-y: auto;
  z-index: 888;

  h3 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
  }
}

.back {
  cursor: pointer;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
  background: rgba(black, .7);
  z-index: 666;
}

.body-bg {
  background: white;
}

a, button {
  position: relative;
}

.list-product-compra {
  padding: 0;

  li {
    display: block;
    border: 1px solid grey;
    background: white;
    margin-bottom: 2px;

    h3, h4 {
      background: black;
      color: white;
      margin: 0;
      display: block;
      text-transform: uppercase;
      font-size: 1.4rem;
      padding: 5px;
    }
  }
}

.card {
  //box-shadow: 0 0 5px 1px grey;
  background: white;
  border: none;

  .card-header {
    background: #f3f3f3;

    > span, h3 {
      font-weight: 800;
    }
  }

  h3.card-header {
    text-transform: uppercase;
    font-size: 20px;
    padding: 10px 10px 10px 20px;
    font-weight: 700;
    letter-spacing: 1px;
    color: white;
    background-color: black;
    border: none;
  }
}


.card-sys {
  padding: 15px;
  background: white;
  box-shadow: 0 0 2px grey;
  margin-bottom: 10px;
}

a[data-title]:hover:after, button[data-title]:hover:after {
  @extend .animated;
  @extend .bounceIn;
  content: attr(data-title);
  padding: 4px 8px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1500;
  white-space: nowrap;
  border-radius: 2px;
  background: black;
  color: white;
}

#logo-panel {
  padding: 3px 15px;

  svg {
    max-height: 50px;
  }
}

.title-section {
  text-transform: uppercase;
  font-weight: 400 !important;
  text-align: left;
  letter-spacing: 2px;
  margin: 0 !important;
  color: white !important;
  padding-bottom: 10px;
}

.user-image {
  img {
    border: none;
  }
}

.regiter-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @include mobile {
    width: 80%;
  }

  h3 {
    img {
      width: 50%;
    }
  }
}

.login-area {
  @include mobile {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: fixed;
  }
}

.result-item {
  padding: 10px;
  border: 1px solid rgba(black, .1);
  margin-bottom: 5px;
  margin-top: 3px;
  display: block;
  transition: all .3s;


  h4 {
    margin: 0;
    margin-left: 10px;
    text-transform: uppercase;
  }


}

ul.pagination {
  display: flex;
  width: 90%;
  margin: 15px auto;

  > li {
    flex: 1;

    a {
      border: 1px solid rgba(179, 179, 179, 0.6);
      display: block;
      //padding: 5px;
      text-align: center;

      &:hover, &.active {
        background-color: rgba(#d3d3d3, .5);
      }
    }

    &:first-child {
      a {
        border-radius: 5px 0 0 5px;
      }
    }

    &:last-child {
      a {
        border-radius: 0 5px 5px 0;
      }
    }

  }
}

.pagination {

  .pagination-link {
    background: $cyan;
    color: white;

    &.is-current {
      background-color: transparent;
      border: 1px solid white;
      transform: translateY(2px);
    }

  }

  .pagination-previous, .pagination-next {
    color: white;
  }

}

.table-precios {
  overflow-x: scroll;
  transition: all .3s ease-in;

  table {
    width: 180vw !important;
    max-width: unset !important;

    form {
      padding: 0;
      margin: 0;
    }
  }
}

.pagination-list {
  margin-bottom: 0 !important;
}

.item-message-ml {
  border-bottom: 1px solid lightgrey;
  font-size: 13px;
}

.sorting:after, .sorting:before {
  //font-size: 20px !important;
  color: $color_fondo;
  font-weight: 900;
  font-family: Arial, sans-serif;
}

#table-archivos, #table-reporte {

  .sorting_asc {
    background-image: url("../img/asc.png") !important;
    background-color: lightgrey;
    background-position: 98% center;
  }

  .sorting_desc {
    background-image: url("../img/desc.png") !important;
    background-color: lightgrey;
    background-position: 98% center;
  }
}
