header.header-sistema {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 2px grey;
  z-index: 888;
  height: auto;
  width: 100%;
  padding: 10px 0;
  background: $gris;
  border-bottom: 1px solid white;

  a.open-menu {
    color: white;
  }
}

.back-menu {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(black, .4);
  left: 0;
  top: 0;
  z-index: 777;
  display: none;
}