section[data-section="abm-colores"] {


  input[type='color'] {
    -webkit-appearance: none;
    //border: none;
    width: 100%;
    height: 35px !important;
  }

  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 5px;
  }

  input[type="color"]::-webkit-color-swatch {
    border: none;
  }

  ul {
    padding: 0;

    li {
      list-style: none;
    }
  }
}