#table-frecuentes
{
  margin-top: 16px;

  input[type='number']
  {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(grey,.3);
    box-shadow: none;
    background: transparent;
  }
}