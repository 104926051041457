section[data-section="galeria_cero"] {

  .galeria {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    //align-items: center;

    .item-galeria {
      padding: 10px;
      flex: 0 20%;
      flex-direction: column;
      display: flex;
      justify-content: center;
      border: 1px solid grey;

      @include mobile {
        flex: 0 100%;
      }

      .btn {
        margin: 2px 0;
      }

      img {
        max-height: 170px;

        @include mobile{
          height: auto;
          max-height: unset;
        }
      }
    }
  }

  .drop {
    height: 200px;
    margin: 5px 0;
    border: 1px solid grey;
    cursor: pointer;
  }
}