section[data-section='colores']
{

  .colores
  {
    //border:1px solid red;
    display: flex;
    flex-wrap: wrap;

    .item-color
    {
      margin: 10px 0;
      //flex:0;
      flex-basis: 20%;
      border: 1px solid rgba(black,0.4);
      padding: 10px;
      img{
        max-width: 100%;
      }
    }
  }
}