//colors
$white: #fff;
$color_tuna_approx: #343a40;
$color_abbey_approx: #495057;
$black_14: rgba(0, 0, 0, 0.14);
$black_12: rgba(0, 0, 0, 0.12);
$black_40: rgba(0, 0, 0, 0.4);
$color_mercury_approx: #e7e7e7;
$alabaster: #fafafa;
$color_mountain_mist_approx: #999;
$color_cornflower_blue_approx: #66afe9;
$gray: #808080;
$color_star_dust_approx: #9a9a9a;
$color_ironside_gray_approx: #676767;
$color_gallery_approx: #eee;
$color_alto_approx: #ddd;
$color_celeste_approx: #ccc;
$color_steel_blue_approx: #337ab7;
$color_havelock_blue_approx: #5094ce;
$color_st_tropaz_approx: #286090;
$color_casablanca_approx: #f0ad4e;
$color_rob_roy_approx: #f4c37d;
$color_carrot_orange_approx: #ec971f;
$color_chestnut_rose_approx: #d9534f;
$color_new_york_pink_approx: #e27c79;
$color_persian_red_approx: #c9302c;
$color_fern_approx: #5cb85c;
$color_de_york_approx: #80c780;
$color_apple_approx: #449d44;
$color_viking_approx: #5bc0de;
$color_seagull_approx: #85d0e7;
$color_scooter_approx: #31b0d5;
$color_submarine_approx: #bdc3c7;
$color_mine_shaft_approx: #333;
$color_athens_gray_approx: #ecf0f1;
$color_emperor_approx: #4d4d4d;
$color_pewter_approx: #95a5a6;
$color_curious_blue_approx: #3498db;
$color_suva_gray_approx: #888;
$color_storm_dust_approx: #666;
$black_10: rgba(0, 0, 0, 0.1);
$color_tundora_approx: #444;
$color_tapa_approx: #777;
$color_log_cabin_48_approx: rgba(33, 33, 33, 0.48);
$black_6: rgba(0, 0, 0, 0.06);
$black_20: rgba(0, 0, 0, 0.2);
$black_4: rgba(0, 0, 0, 0.04);
$black_30: rgba(0, 0, 0, 0.3);
$color_bon_jour_approx: #e0e0e0;
$color_emerald_approx: #2ecc71;
$color_chicago_approx: #575757;
$color_silver_approx: #bdbdbd;
$color_mine_shaft_50_approx: rgba(51, 51, 51, 0.5);
$color_mountain_meadow_approx: #1abc9c;
$color_chateau_green_approx: #29b765;
$color_wisteria_approx: #9b59b6;
$color_studio_approx: #8f4bab;
$color_pickled_bluewood_approx: #34495e;
$color_niagara_approx: #16a085;
$color_elf_green_approx: #138a72;
$color_eucalyptus_approx: #229955;
$color_boston_blue_approx: #2980b9;
$color_jelly_bean_approx: #2472a4;
$color_vivid_violet_approx: #7f3d9b;
$color_ebony_clay_approx: #233140;
$color_corn_approx: #f1c40f;
$color_galliano_approx: #dab10d;
$color_zest_approx: #e67e22;
$color_hot_cinnamon_approx: #d67118;
$color_cinnabar_approx: #e74c3c;
$color_regent_gray_approx: #87999a;
$color_tree_poppy_approx: #f39c12;
$color_golden_bell_approx: #e08e0b;
$color_tenn_approx: #d35400;
$color_rose_of_sharon_approx: #ba4a00;
$color_flush_mahogany_approx: #c0392b;
$color_roof_terracotta_approx: #ab3326;
$color_bombay_approx: #afb6bb;
$color_oslo_gray_approx: #7f8c8d;
$color_sirocco_approx: #727f80;
$concrete: #f2f2f2;
$color_shark_approx: #262626;
$color_mariner_approx: #217dbb;
$color_genoa_approx: #107360;
$color_matisse_approx: #20638f;
$color_eminence_approx: #703688;
$color_buddha_gold_approx: #c29d0b;
$color_bourbon_approx: #bf6516;
$color_thunderbird_approx: #d62c1a;
$color_iron_approx: #cfd9db;
$color_juniper_approx: #798d8f;
$color_meteor_approx: #c87f0a;
$color_oregon_approx: #a04000;
$color_prairie_sand_approx: #962d22;
$color_gray_chateau_approx: #a1aab0;
$color_nevada_approx: #667273;
$color_rangoon_green_approx: #1a1a1a;
$color_fuscous_gray_approx: #555;
$white_80: rgba(255, 255, 255, 0.8);
$color_malachite_approx: #07f85d;
$color_outer_space_approx: #2e323a;
$black_99: rgba(0, 0, 0, 0.99);
$white_20: rgba(255, 255, 255, 0.2);
$black_50: rgba(0, 0, 0, 0.5);
$color_azure_radiance_approx: #007bff;
$alabaster_99: rgba(250, 250, 250, 0.99);
$black: #000;
$color_carnation_approx: #ff5252;
$white_70: rgba(255, 255, 255, 0.7);

//@extend-elements
//original selectors
//body >.ember-view, .app
%extend_1 {
  position: absolute !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  min-height: 100%;
}


//body {
//  direction: ltr;
//  > .ember-view {
//    @extend %extend_1;
//  }
//  &:not(.app-side-mini) .app-side .logo .logo-xs {
//    display: none;
//  }
//}
//html {
//  direction: ltr;
//}
//* {
//  outline-width: 0;
//}
.app {
  @extend %extend_1;
}
.app-wrap {
  flex: 1 1 0 !important;
  flex-basis: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  min-height: 100vh;
  position: relative;
  color: $color_mine_shaft_approx;
  font-size: 14px;
  &.container {
    padding-left: 0;
    padding-right: 0;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 4px 5px 0 $black_14, 0 1px 10px 0 $black_12, 0 2px 4px -1px $black_40;
    .navbar {
      &.container > {
        .navbar-header {
          margin-right: 0;
          margin-left: 0;
        }
        .navbar-collapse {
          margin-right: 0;
          margin-left: 0;
        }
      }
      &.container-fluid > {
        .navbar-header {
          margin-right: 0;
          margin-left: 0;
        }
        .navbar-collapse {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }
}
@media(min-width: 768px) {
  .page-fixed {
    &.side-fixed {
      .side-content {
        overflow-y: hidden;
        overflow-x: hidden;
      }
      .app-side:hover .side-content {
        overflow-y: auto;
      }
    }
    &:not(.app-side-expand-on-hover) [data-side="mini"] {
      display: none;
    }
    &.app-side-mini.app-side-expand-on-hover .app-side:hover {
      .side-tools {
        display: block;
      }
      .nav-header {
        display: block;
      }
      .side-footer {
        display: block;
      }
    }
  }
  .app-side-opened {
    .app-side {
    }
    &.app-side-mini {
      .app-side {
        width: 60px;
        z-index: 1;
        .side-nav > ul > li > a > {
          .nav-icon {
            padding-left: 5px;
          }
          .nav-title {
            padding-left: 25px;
            display: none;
          }
          .nav-tools {
            display: none;
          }
        }
      }
      &.app-side-expand-on-hover .app-side:hover {
        width: 240px;
        .side-nav > ul > li > a > {
          .nav-title {
            display: inline-block;
          }
          .nav-tools {
            display: inline-block;
          }
        }
      }
      &:not(.page-fixed):not(.app-side-expand-on-hover) .app-side .side-nav > ul > li {
        &.active {
          > a {
            min-width: 240px;
            top: 0;
            .nav-title {
              display: inline-block;
            }
            .nav-tools {
              display: inline-block;
            }
          }
          > ul.collapse.in {
            min-width: 180px;
            left: 100%;
            top: 100%;
          }
        }
        > ul {
          &.collapsing {
            min-width: 180px;
            left: 100%;
            top: 100%;
          }
          &.collapse {
            min-width: 180px;
            left: 100%;
            top: 100%;
          }
        }
        &:hover > a {
          min-width: 240px;
          top: 0;
          .nav-title {
            display: inline-block;
          }
          .nav-tools {
            display: inline-block;
          }
        }
      }
    }
  }
  .app-side-mini {
    .app-side {
      .side-tools {
        display: none;
      }
      .nav-header {
        display: none;
      }
    }
    .side-footer {
      display: none;
    }
  }
  .nav {
    &.is-center {
      display: flex !important;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    &.is-right {
      display: flex !important;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      > li > ul {
        left: auto;
        right: 0;
      }
    }
    &.nav-hoverable > li:hover > .nav-sub {
      display: block !important;
    }
    &.nav-inline > li {
      > .nav-sub {
        background-color: $white;
        z-index: 1000;
        position: absolute;
        top: 100%;
        &.collapse {
          position: absolute;
          top: 100%;
        }
        &.collapsing {
          position: absolute;
          top: 100%;
        }
      }
      &:last-child > .nav-sub {
        left: auto;
        right: 0;
      }
    }
  }
  .nav-bordered {
    > li > .nav-sub {
      background-color: $white;
      z-index: 1000;
      position: absolute;
      top: 0;
      left: 100%;
      &.collapse {
        position: absolute;
        top: 0;
        left: 100%;
      }
      &.collapsing {
        position: absolute;
        top: 0;
        left: 100%;
      }
    }
    &.nav-inline > li > .nav-sub {
      background-color: $white;
      z-index: 1000;
      position: absolute;
      top: 100%;
      left: 0;
      &.collapse {
        position: absolute;
        top: 100%;
        left: 0;
      }
      &.collapsing {
        position: absolute;
        top: 100%;
        left: 0;
      }
    }
  }
  .nav-full-item {
    position: absolute;
    top: 100% !important;
    left: 0 !important;
    right: 0 !important;
  }
  .dashhead {
    flex-direction: row;
  }
  .dashhead-titles {
    margin-bottom: 0;
  }
  .dashhead-toolbar {
    flex-direction: row;
    align-items: center;
  }
  .dashhead-toolbar-item {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  .timeline {
    padding: 10px 0;
    &:before {
      left: 20px;
    }
    &.is-right {
      .timeline-heading {
        text-align: right;
      }
      &:before {
        left: auto;
        right: 20px;
      }
    }
    &.is-center {
      &:before {
        left: 50%;
      }
      .timeline-icon {
        position: absolute;
        left: calc(50% - 40px / 2);
      }
      .timeline-info {
        flex-direction: row;
      }
      .timeline-heading {
        flex: 50% 0 0;
        text-align: right;
        padding-right: 35px;
        &.is-last {
          order: 1;
          text-align: left;
          padding-left: 35px;
          + .timeline-content {
            padding-right: 35px;
          }
        }
        &:not(.is-last) + .timeline-content {
          padding-left: 35px;
        }
      }
      .timeline-content {
        flex: 50% 0 0;
        &.is-last {
          order: 1;
        }
      }
    }
  }
  .timeline-heading {
    margin: 0 0 15px 0;
    text-align: left;
  }
  .timeline-item {
    flex-direction: row;
    align-items: flex-start;
    margin: 20px 0;
    + .timeline-item {
      margin-top: 40px;
    }
  }
  .timeline-icon {
    margin: 0;
  }
  .navbar-header-left {
    width: 240px;
  }
  .app-login {
    min-width: 480px;
  }
  .columns {
    display: flex;
    flex: 1 1 0;
    flex-direction: row;
    overflow-y: auto;
    position: relative;
  }
  .column {
    overflow-y: auto;
    flex: 1 1 0;
    &.is-fixed {
      flex: 0 0 240px;
    }
  }
  .app-wrap.container {
    flex: 0 0 750px !important;
  }
}
@media(min-width: 992px) {
  .app-wrap.container {
    flex: 0 0 970px !important;
  }
}
@media(min-width: 1200px) {
  .app-wrap.container {
    flex: 0 0 1170px !important;
  }
}
.app-heading {
  min-height: 60px;
  position: relative;
  z-index: 2;
  .form-control {
    font-size: 14px;
  }
  .dropdown-menu {
    font-size: 14px;
  }
  nav.navbar {
    padding: 0;
  }
}
.app-container {
  display: flex !important;
  flex: 1 1 auto !important;
  flex-basis: auto !important;
  flex-direction: row !important;
  position: relative;
}
.side-visible-line {
  background: $white;
  border-right: 1px solid $color_mercury_approx;
  padding: 5px 3px;
  cursor: pointer;
  &:hover {
    background: $alabaster;
  }
}
.app-main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}
.main-content {
  position: relative;
  margin-bottom: auto;
  flex: 1 1 auto;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  .container-fluid {
    width: 100%;
  }
}
.page-fixed {
  .app {
    height: 100%;
  }
  .app-container {
    overflow-y: auto;
  }
  .app-main {
    overflow-y: auto;
  }
  .app-side {
    overflow-y: auto;
  }
  &.main-fixed {
    .app-main {
      display: flex !important;
      flex-direction: column !important;
    }
    .main-content {
      flex: 1 1 0 !important;
      flex-basis: 0 !important;
      display: flex !important;
      flex-direction: column !important;
      flex-shrink: 1;
      overflow-y: auto;
      .container-fluid {
        width: 100%;
      }
    }
    #totop {
      display: none !important;
    }
  }
  #totop {
    display: block !important;
    right: 5px;
    bottom: 5px;
  }
  body > .ember-view {
    height: 100%;
  }
  &.side-fixed .side-content {
    overflow-y: auto;
  }
}
.app-side {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translateX(-100%);
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.3s ease-out;
  display: flex;
  flex-flow: column nowrap;
  background: $color_outer_space_approx;
  color: $white_80;
  .side-nav {
    ul {
      background: $color_outer_space_approx;
      color: $white_80;
    }
    a {
      background: $color_outer_space_approx;
      color: $white_80;
      &:hover {
        color: $white;
        background: $black_99;
      }
      &:focus {
        color: $white;
        background: $black_99;
      }
      &.active {
        color: $white;
        &:hover {
          color: $white;
        }
        &:focus {
          color: $white;
        }
      }
    }
    li.active > a {
      color: $white;
      background: $black_99;
    }
  }
  .dropdown-menu {
    font-size: 14px;
  }
  .nav .nav-divider {
    background-color: $white_20;
  }
}
.app-side-opened {
  .app-side {
    position: absolute !important;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateX(0);
  }
  &.app-side-mini {
    aside.app-side .nav {
      > li > a {
        width: 60px;
      }
      &.nav-sub > li > a {
        width: auto;
      }
    }
    &:not(.page-fixed):not(.app-side-expand-on-hover) .app-side .side-nav > ul > li {
      > ul.collapse {
        position: static !important;
        margin-left: 60px;
      }
      &.active > ul.collapse.in {
        position: static !important;
        margin-left: 60px;
      }
    }
  }
}
@media(max-width: 767px) {
  .app-side {
    overflow-y: auto;
    &.ps {
      position: fixed;
    }
  }
  .app-side-opened {
    .app-side {
      z-index: 1000;
    }
    .app-heading .navbar-fixed-top {
      position: relative !important;
    }
  }
  .navbar-form {
    padding: 10px 15px;
  }
  .columns {
    flex: 1 1 0;
    flex-basis: 0;
  }
  .column {
    &.is-fixed {
      position: fixed;
      top: 0;
      width: 240px;
      height: 100%;
      z-index: 1;
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: translateX(-100%);
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: transform 0.3s ease-out;
      &.is-open {
        position: absolute;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateX(0);
      }
    }
    &.is-flexible ~ .column.is-fixed {
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: translateX(100%);
      left: auto;
      right: -240px;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.3s ease-out;
      &.is-open {
        right: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateX(0);
      }
    }
  }
  .page-fixed.app-side-opened .app-side {
    z-index: 1001;
  }
  .top-nav .nav.nav-inline {
    > li {
      display: block;
    }
    &.collapse:not(.in) {
      display: none;
    }
    &.is-center:not(.collapse) {
      flex-direction: column;
    }
    &.is-right:not(.collapse) {
      flex-direction: column;
    }
  }
}
.side-heading {
  width: 100%;
}
.user-panel {
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.user-info {
  display: flex;
  flex-direction: column;
  flex: 1 1 0 !important;
  flex-basis: 0 !important;
  padding-left: 10px;
  h5 {
    margin: 0 0 10px 0;
    font-weight: 400;
    color: $white;
  }
  .dropdown > a {
    font-size: 12px;
    padding: 0 !important;
    background: transparent !important;
    &:hover {
      background: transparent !important;
    }
    &:focus {
      background: transparent !important;
    }
    &.text-turquoise {
      color: $color_malachite_approx !important;
      font-size: 14px;
    }
    &.dropdown-toggle::after {
      border-top: .4em solid $color_malachite_approx;
    }
  }
}
.app-side-mini {
  .user-info {
    display: none !important;
  }
  &.app-side-expand-on-hover .app-side:hover {
    .user-info {
      display: flex !important;
    }
    .logo-lg {
      display: block;
    }
    .logo-xs {
      display: none;
    }
  }
  .app-side .logo .logo-lg {
    display: none;
  }
}
.side-content {
  flex: 1 1 auto !important;
  flex-basis: auto !important;
  margin-bottom: auto;
}
.app-side-closed {
  .app-side {
    position: absolute !important;
  }
  [data-side="mini"] {
    display: none !important;
  }
}
.form-control {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  &:focus {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
  }
}
.input-sm {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}
.input-lg {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}
.input-group-addon {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  &.input-sm {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
  }
  &.input-lg {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
  }
  &:not(:first-child):not(:last-child) {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
  }
}
.input-group {
  .btn {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
  }
  .form-control:not(:first-child):not(:last-child) {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
  }
}
.switch {
  input[type="checkbox"].hidden {
    + .fa:before {
      content: "\f204";
    }
    &:checked + .fa:before {
      content: "\f205";
    }
  }
  input[type="radio"].hidden {
    + .fa:before {
      content: "\f204";
    }
    &:checked + .fa:before {
      content: "\f205";
    }
  }
}
.checkbox {
  input[type="checkbox"].hidden + .fa {
    margin-left: -20px;
  }
  input[type="radio"].hidden + .fa {
    margin-left: -20px;
  }
}
.radio {
  input[type="checkbox"].hidden + .fa {
    margin-left: -20px;
  }
  input[type="radio"].hidden + .fa {
    margin-left: -20px;
  }
}
.form-inline {
  .checkbox {
    input[type="checkbox"].hidden + .fa {
      margin-left: 0;
    }
    input[type="radio"].hidden + .fa {
      margin-left: 0;
    }
  }
  .radio {
    input[type="checkbox"].hidden + .fa {
      margin-left: 0;
    }
    input[type="radio"].hidden + .fa {
      margin-left: 0;
    }
  }
}
.icon-before-input {
  width: 100%;
  position: relative;
  vertical-align: middle;
  > {
    .form-control {
      padding-left: 40px;
    }
    .icon {
      left: 0;
      padding-left: 10px;
      position: absolute;
      top: 50%;
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: translateY(-50%);
      color: $color_mountain_mist_approx;
      a {
        background: transparent;
        border: 0;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0;
        cursor: pointer;
        color: $color_mountain_mist_approx;
      }
      button:not([class*=btn]) {
        background: transparent;
        border: 0;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0;
        cursor: pointer;
        color: $color_mountain_mist_approx;
      }
    }
  }
  input:focus + .icon {
    color: $color_cornflower_blue_approx;
    a {
      color: $color_cornflower_blue_approx;
    }
    button:not([class*=btn]) {
      color: $color_cornflower_blue_approx;
    }
  }
}
.icon-after-input {
  width: 100%;
  position: relative;
  vertical-align: middle;
  > {
    .form-control {
      padding-right: 40px;
    }
    .icon {
      right: 0;
      padding-right: 10px;
      position: absolute;
      top: 50%;
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: translateY(-50%);
      color: $color_mountain_mist_approx;
      a {
        background: transparent;
        border: 0;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0;
        cursor: pointer;
        color: $color_mountain_mist_approx;
      }
      button:not([class*=btn]) {
        background: transparent;
        border: 0;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0;
        cursor: pointer;
        color: $color_mountain_mist_approx;
      }
    }
  }
  input:focus + .icon {
    color: $color_cornflower_blue_approx;
    a {
      color: $color_cornflower_blue_approx;
    }
    button:not([class*=btn]) {
      color: $color_cornflower_blue_approx;
    }
  }
}
.btn-rect {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 !important;
}
.btn {
  &.btn-pill {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 50px;
  }
  &.btn-circle {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 50%;
    padding: 0;
    width: 30px;
    height: 30px;
    &.btn-xs {
      width: 20px;
      height: 20px;
    }
    &.btn-sm {
      width: 40px;
      height: 40px;
    }
    &.btn-md {
      width: 50px;
      height: 50px;
    }
    &.btn-lg {
      width: 60px;
      height: 60px;
    }
    &.btn-xl {
      width: 70px;
      height: 70px;
    }
  }
}
.btn-line {
  &.btn-default {
    color: $gray;
    background: none;
    border: 1px solid $gray;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: none;
    &:hover {
      background: none;
      color: $color_star_dust_approx;
      border-color: $color_star_dust_approx;
    }
    &:focus {
      background: none;
      color: $color_star_dust_approx;
      border-color: $color_star_dust_approx;
    }
    &:active {
      background: none;
      color: $color_ironside_gray_approx;
      border-color: $color_ironside_gray_approx;
    }
    &.active {
      background: none;
      color: $color_ironside_gray_approx;
      border-color: $color_ironside_gray_approx;
    }
    &.disabled {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
      &:hover {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &:focus {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &:active {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &.active {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
    }
  }
  &.btn-default[disabled] {
    background-color: $color_gallery_approx;
    border: 1px solid $color_alto_approx;
    color: $color_celeste_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 1px $white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:focus {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &.active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }
  &.btn-primary {
    color: $color_steel_blue_approx;
    background: none;
    border: 1px solid $color_steel_blue_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: none;
    &:hover {
      background: none;
      color: $color_havelock_blue_approx;
      border-color: $color_havelock_blue_approx;
    }
    &:focus {
      background: none;
      color: $color_havelock_blue_approx;
      border-color: $color_havelock_blue_approx;
    }
    &:active {
      background: none;
      color: $color_st_tropaz_approx;
      border-color: $color_st_tropaz_approx;
    }
    &.active {
      background: none;
      color: $color_st_tropaz_approx;
      border-color: $color_st_tropaz_approx;
    }
    &.disabled {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
      &:hover {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &:focus {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &:active {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &.active {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
    }
  }
  &.btn-primary[disabled] {
    background-color: $color_gallery_approx;
    border: 1px solid $color_alto_approx;
    color: $color_celeste_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 1px $white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:focus {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &.active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }
  &.btn-warning {
    color: $color_casablanca_approx;
    background: none;
    border: 1px solid $color_casablanca_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: none;
    &:hover {
      background: none;
      color: $color_rob_roy_approx;
      border-color: $color_rob_roy_approx;
    }
    &:focus {
      background: none;
      color: $color_rob_roy_approx;
      border-color: $color_rob_roy_approx;
    }
    &:active {
      background: none;
      color: $color_carrot_orange_approx;
      border-color: $color_carrot_orange_approx;
    }
    &.active {
      background: none;
      color: $color_carrot_orange_approx;
      border-color: $color_carrot_orange_approx;
    }
    &.disabled {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
      &:hover {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &:focus {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &:active {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &.active {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
    }
  }
  &.btn-warning[disabled] {
    background-color: $color_gallery_approx;
    border: 1px solid $color_alto_approx;
    color: $color_celeste_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 1px $white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:focus {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &.active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }
  &.btn-danger {
    color: $color_chestnut_rose_approx;
    background: none;
    border: 1px solid $color_chestnut_rose_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: none;
    &:hover {
      background: none;
      color: $color_new_york_pink_approx;
      border-color: $color_new_york_pink_approx;
    }
    &:focus {
      background: none;
      color: $color_new_york_pink_approx;
      border-color: $color_new_york_pink_approx;
    }
    &:active {
      background: none;
      color: $color_persian_red_approx;
      border-color: $color_persian_red_approx;
    }
    &.active {
      background: none;
      color: $color_persian_red_approx;
      border-color: $color_persian_red_approx;
    }
    &.disabled {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
      &:hover {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &:focus {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &:active {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &.active {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
    }
  }
  &.btn-danger[disabled] {
    background-color: $color_gallery_approx;
    border: 1px solid $color_alto_approx;
    color: $color_celeste_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 1px $white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:focus {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &.active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }
  &.btn-success {
    color: $color_fern_approx;
    background: none;
    border: 1px solid $color_fern_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: none;
    &:hover {
      background: none;
      color: $color_de_york_approx;
      border-color: $color_de_york_approx;
    }
    &:focus {
      background: none;
      color: $color_de_york_approx;
      border-color: $color_de_york_approx;
    }
    &:active {
      background: none;
      color: $color_apple_approx;
      border-color: $color_apple_approx;
    }
    &.active {
      background: none;
      color: $color_apple_approx;
      border-color: $color_apple_approx;
    }
    &.disabled {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
      &:hover {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &:focus {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &:active {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &.active {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
    }
  }
  &.btn-success[disabled] {
    background-color: $color_gallery_approx;
    border: 1px solid $color_alto_approx;
    color: $color_celeste_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 1px $white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:focus {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &.active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }
  &.btn-info {
    color: $color_viking_approx;
    background: none;
    border: 1px solid $color_viking_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: none;
    &:hover {
      background: none;
      color: $color_seagull_approx;
      border-color: $color_seagull_approx;
    }
    &:focus {
      background: none;
      color: $color_seagull_approx;
      border-color: $color_seagull_approx;
    }
    &:active {
      background: none;
      color: $color_scooter_approx;
      border-color: $color_scooter_approx;
    }
    &.active {
      background: none;
      color: $color_scooter_approx;
      border-color: $color_scooter_approx;
    }
    &.disabled {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
      &:hover {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &:focus {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &:active {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
      &.active {
        background-color: $color_gallery_approx;
        border: 1px solid $color_alto_approx;
        color: $color_celeste_approx;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 1px $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
      }
    }
  }
  &.btn-info[disabled] {
    background-color: $color_gallery_approx;
    border: 1px solid $color_alto_approx;
    color: $color_celeste_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 1px $white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:focus {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &.active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }
}
.btn-flat {
  border-width: 0 !important;
  &.btn-default {
    background-color: $color_alto_approx;
  }
}
.btn-outline {
  &.btn-default {
    color: $gray;
    background-color: transparent;
    border-color: $gray;
    &:focus {
      color: $white;
      background-color: $gray;
      border-color: $gray;
    }
    &.focus {
      color: $white;
      background-color: $gray;
      border-color: $gray;
    }
    &:hover {
      color: $white;
      background-color: $gray;
      border-color: $gray;
    }
    &:active {
      color: $white;
      background-color: $gray;
      border-color: $gray;
    }
    &.active {
      color: $white;
      background-color: $gray;
      border-color: $gray;
    }
  }
  &.btn-primary {
    color: $color_steel_blue_approx;
    background-color: transparent;
    border-color: $color_steel_blue_approx;
    &:focus {
      color: $white;
      background-color: $color_steel_blue_approx;
      border-color: $color_steel_blue_approx;
    }
    &.focus {
      color: $white;
      background-color: $color_steel_blue_approx;
      border-color: $color_steel_blue_approx;
    }
    &:hover {
      color: $white;
      background-color: $color_steel_blue_approx;
      border-color: $color_steel_blue_approx;
    }
    &:active {
      color: $white;
      background-color: $color_steel_blue_approx;
      border-color: $color_steel_blue_approx;
    }
    &.active {
      color: $white;
      background-color: $color_steel_blue_approx;
      border-color: $color_steel_blue_approx;
    }
  }
  &.btn-warning {
    color: $color_casablanca_approx;
    background-color: transparent;
    border-color: $color_casablanca_approx;
    &:focus {
      color: $white;
      background-color: $color_casablanca_approx;
      border-color: $color_casablanca_approx;
    }
    &.focus {
      color: $white;
      background-color: $color_casablanca_approx;
      border-color: $color_casablanca_approx;
    }
    &:hover {
      color: $white;
      background-color: $color_casablanca_approx;
      border-color: $color_casablanca_approx;
    }
    &:active {
      color: $white;
      background-color: $color_casablanca_approx;
      border-color: $color_casablanca_approx;
    }
    &.active {
      color: $white;
      background-color: $color_casablanca_approx;
      border-color: $color_casablanca_approx;
    }
  }
  &.btn-danger {
    color: $color_chestnut_rose_approx;
    background-color: transparent;
    border-color: $color_chestnut_rose_approx;
    &:focus {
      color: $white;
      background-color: $color_chestnut_rose_approx;
      border-color: $color_chestnut_rose_approx;
    }
    &.focus {
      color: $white;
      background-color: $color_chestnut_rose_approx;
      border-color: $color_chestnut_rose_approx;
    }
    &:hover {
      color: $white;
      background-color: $color_chestnut_rose_approx;
      border-color: $color_chestnut_rose_approx;
    }
    &:active {
      color: $white;
      background-color: $color_chestnut_rose_approx;
      border-color: $color_chestnut_rose_approx;
    }
    &.active {
      color: $white;
      background-color: $color_chestnut_rose_approx;
      border-color: $color_chestnut_rose_approx;
    }
  }
  &.btn-success {
    color: $color_fern_approx;
    background-color: transparent;
    border-color: $color_fern_approx;
    &:focus {
      color: $white;
      background-color: $color_fern_approx;
      border-color: $color_fern_approx;
    }
    &.focus {
      color: $white;
      background-color: $color_fern_approx;
      border-color: $color_fern_approx;
    }
    &:hover {
      color: $white;
      background-color: $color_fern_approx;
      border-color: $color_fern_approx;
    }
    &:active {
      color: $white;
      background-color: $color_fern_approx;
      border-color: $color_fern_approx;
    }
    &.active {
      color: $white;
      background-color: $color_fern_approx;
      border-color: $color_fern_approx;
    }
  }
  &.btn-info {
    color: $color_viking_approx;
    background-color: transparent;
    border-color: $color_viking_approx;
    &:focus {
      color: $white;
      background-color: $color_viking_approx;
      border-color: $color_viking_approx;
    }
    &.focus {
      color: $white;
      background-color: $color_viking_approx;
      border-color: $color_viking_approx;
    }
    &:hover {
      color: $white;
      background-color: $color_viking_approx;
      border-color: $color_viking_approx;
    }
    &:active {
      color: $white;
      background-color: $color_viking_approx;
      border-color: $color_viking_approx;
    }
    &.active {
      color: $white;
      background-color: $color_viking_approx;
      border-color: $color_viking_approx;
    }
  }
}
.nav-header {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 5px;
  font-size: 85%;
  font-weight: normal;
  letter-spacing: 1px;
  color: $color_submarine_approx;
  text-transform: uppercase;
}
.nav {
  > li {
    &.disabled > a {
      color: $color_submarine_approx;
      cursor: not-allowed;
      background-color: transparent;
      &:hover {
        color: $color_submarine_approx;
        cursor: not-allowed;
        background-color: transparent;
      }
      &:focus {
        color: $color_submarine_approx;
        cursor: not-allowed;
        background-color: transparent;
      }
    }
    > a.active {
      color: $color_curious_blue_approx;
      color: $color_curious_blue_approx;
    }
    &.active > a {
      color: $color_curious_blue_approx;
    }
  }
  &.nav-inline {
    float: none !important;
    > a {
      display: inline-block;
    }
    > li {
      display: inline-block;
    }
  }
  .nav-divider {
    height: 1px;
    margin: 0;
    overflow: hidden;
  }
  &.nav-stacked .nav-divider {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
  a.disabled {
    color: $color_submarine_approx;
    cursor: not-allowed;
    background-color: transparent;
    &:hover {
      color: $color_submarine_approx;
      cursor: not-allowed;
      background-color: transparent;
    }
    &:focus {
      color: $color_submarine_approx;
      cursor: not-allowed;
      background-color: transparent;
    }
  }
  > a.active {
    color: $color_curious_blue_approx;
    color: $color_curious_blue_approx;
  }
  li.active ul.nav.nav-sub.collapse.in li a {
    background-color: $black_50;
  }
  &.nav-bordered > li {
    &.active:not(.disabled) > a::after {
      background: $color_carnation_approx;
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: scale3d(1, 1, 1);
    }
    &:not(.disabled) > a.active::after {
      background: $color_carnation_approx;
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: scale3d(1, 1, 1);
    }
  }
}
.nav-sub {
  min-width: 180px;
}
.nav-bordered {
  margin: 0;
  > li {
    > a {
      border-color: transparent;
      &:hover {
        background: none;
      }
      &:focus {
        background: none;
      }
    }
    &:not(.disabled) > a:not(.disabled) {
      &:after {
        position: absolute;
        height: 100%;
        width: 4px;
        content: '';
        left: 0;
        bottom: 0;
        background: $color_submarine_approx;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0, 0, 1);
        //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
        transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
      }
      &:hover:after {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(1, 1, 1);
      }
      &:focus:after {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(1, 1, 1);
      }
    }
    &.active > a {
      &:after {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(1, 1, 1);
      }
      &:hover:after {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(1, 1, 1);
      }
      &:focus:after {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(1, 1, 1);
      }
    }
    &.disabled > a {
      &:hover:after {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0, 0, 1);
      }
      &:focus:after {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0, 0, 1);
      }
    }
  }
  > a {
    &.active {
      &:after {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(1, 1, 1);
      }
      &:hover:after {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(1, 1, 1);
      }
      &:focus:after {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(1, 1, 1);
      }
    }
    &.disabled {
      &:hover:after {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0, 0, 1);
      }
      &:focus:after {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0, 0, 1);
      }
    }
    &:not(.disabled):after {
      position: absolute;
      height: 100%;
      width: 4px;
      content: '';
      left: 0;
      bottom: 0;
      background: $color_submarine_approx;
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: scale3d(0, 0, 1);
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
    }
  }
  &.nav-inline {
    > li:not(.disabled) > a:not(.disabled):after {
      height: 4px;
      width: 100%;
    }
    > a:not(.disabled):after {
      height: 4px;
      width: 100%;
    }
  }
  .nav-header {
    margin: 0;
  }
}
.nav-mega {
  position: relative;
  width: 100%;
}
.nav-full {
  position: relative;
  width: 100%;
}
.nav-full-item {
  background: $white;
  padding: 15px;
  z-index: 1;
}
.nav-inverse {
  background: $color_mine_shaft_approx;
  color: $color_submarine_approx;
  > a {
    color: $color_submarine_approx;
    &:focus {
      color: $color_athens_gray_approx;
      background: $color_emperor_approx;
    }
    &:hover {
      color: $color_athens_gray_approx;
      background: $color_emperor_approx;
    }
  }
  .nav-divider {
    background: $color_pewter_approx;
  }
  > li > a {
    color: $color_submarine_approx;
    &:focus {
      color: $color_athens_gray_approx;
      background: $color_emperor_approx;
    }
    &:hover {
      color: $color_athens_gray_approx;
      background: $color_emperor_approx;
    }
  }
}
.nav-tabs {
  &.is-right > li:last-child > a {
    margin-right: 0;
  }
  &.nav-bordered > li {
    > a {
      border-color: transparent;
      &:focus {
        border-color: transparent;
      }
      &:hover {
        border-color: transparent;
      }
    }
    &.active > a {
      border-color: transparent;
      &:focus {
        border-color: transparent;
      }
      &:hover {
        border-color: transparent;
      }
    }
    &:not(.disabled) > a:after {
      height: 2px;
      width: 100%;
    }
  }
}
.tab-custom {
  display: flex !important;
  flex-direction: row;
  .nav-tabs {
    border-bottom-color: transparent;
    border-right: 1px solid $color_alto_approx;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    > li {
      margin-bottom: 2px;
      margin-right: -3px;
      &:last-child {
        margin-bottom: 0;
      }
      > a {
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 4px 0 0 4px;
        &:hover {
          border-color: $color_gallery_approx $color_alto_approx $color_gallery_approx $color_gallery_approx;
        }
      }
      &.active > a {
        border-color: $color_alto_approx transparent $color_alto_approx $color_alto_approx;
        &:hover {
          border-color: $color_alto_approx transparent $color_alto_approx $color_alto_approx;
        }
        &:focus {
          border-color: $color_alto_approx transparent $color_alto_approx $color_alto_approx;
        }
      }
    }
    &.nav-bordered > li {
      margin-left: 0;
      margin-right: -2px;
      > a {
        border-color: transparent !important;
        &:hover {
          border-color: transparent !important;
        }
        &:focus {
          border-color: transparent !important;
        }
      }
      &.active > a {
        border-color: transparent !important;
        &:hover {
          border-color: transparent !important;
        }
        &:focus {
          border-color: transparent !important;
        }
      }
      &:not(.disabled) > a::after {
        height: 100%;
        width: 2px;
        left: auto;
        right: 0;
      }
    }
  }
  .tab-content {
    padding: 0 15px;
    flex: 1 1 0 !important;
    flex-basis: 0 !important;
  }
  &.is-right .nav-tabs {
    border-right-width: 0;
    border-left: 1px solid $color_alto_approx;
    order: 1;
    > li {
      margin-right: 0;
      margin-left: -1px;
      > a {
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 0 4px 4px 0;
        &:hover {
          border-color: $color_gallery_approx $color_gallery_approx $color_gallery_approx $color_alto_approx;
        }
      }
      &.active > a {
        border-color: $color_alto_approx transparent $color_alto_approx $color_alto_approx;
        border-color: $color_alto_approx $color_alto_approx $color_alto_approx transparent;
        &:hover {
          border-color: $color_alto_approx transparent $color_alto_approx $color_alto_approx;
          border-color: $color_alto_approx $color_alto_approx $color_alto_approx transparent;
        }
        &:focus {
          border-color: $color_alto_approx transparent $color_alto_approx $color_alto_approx;
          border-color: $color_alto_approx $color_alto_approx $color_alto_approx transparent;
        }
      }
    }
    &.nav-bordered > li:not(.disabled) > a::after {
      left: 0;
      right: auto;
    }
  }
}
.box {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  &:not([class*="bg-"]) {
    background: $white;
  }
  > header {
    background-color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    //Instead of the line below you could use @include border-top-left-radius($radius)
    border-top-left-radius: inherit;
    //Instead of the line below you could use @include border-top-right-radius($radius)
    border-top-right-radius: inherit;
    > h1 {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    h2 {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    h3 {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    h4 {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    h5 {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    h6 {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    .box-title {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    &.bg-white a {
      color: $color_mine_shaft_approx;
    }
  }
  .box-heading {
    background-color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    //Instead of the line below you could use @include border-top-left-radius($radius)
    border-top-left-radius: inherit;
    //Instead of the line below you could use @include border-top-right-radius($radius)
    border-top-right-radius: inherit;
    > h1 {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    h2 {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    h3 {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    h4 {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    h5 {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    h6 {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    .box-title {
      margin: 0;
      flex: 1 1 auto;
      color: inherit;
    }
    &.bg-white a {
      color: $color_mine_shaft_approx;
    }
  }
  > footer {
    padding: 10px 15px;
  }
  &:-webkit-full-screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .box-body {
      overflow-y: auto;
    }
  }
  &:-moz-full-screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .box-body {
      overflow-y: auto;
    }
  }
  &:-ms-fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .box-body {
      overflow-y: auto;
    }
  }
  &:fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .box-body {
      overflow-y: auto;
    }
  }
  > header[class*="bg-"] a {
    color: $white;
  }
  .box-heading[class*="bg-"] a {
    color: $white;
  }
}
.box-body {
  padding: 10px 15px;
  //Instead of the line below you could use @include border-bottom-left-radius($radius)
  border-bottom-left-radius: inherit;
  //Instead of the line below you could use @include border-bottom-right-radius($radius)
  border-bottom-right-radius: inherit;
  flex: 1 1 auto !important;
  flex-basis: auto !important;
}
.box-tools {
  margin-left: auto;
  ul {
    margin: 0;
  }
  a {
    font-size: 14px;
    text-decoration: none;
    color: $color_suva_gray_approx;
    &:hover {
      color: $color_storm_dust_approx;
    }
    &:focus {
      color: $color_storm_dust_approx;
    }
  }
  .dropdown ul.dropdown-menu.pull-right.show {
    padding: 10px;
  }
  .label.label-success {
    background-color: $color_emerald_approx;
    padding: 5px 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    color: $white;
  }
}
.dashhead {
  display: flex !important;
  flex-direction: column;
  padding: 10px 15px;
  align-items: stretch;
}
.dashhead-titles {
  flex: 1 1 0 !important;
  flex-basis: 0 !important;
  display: flex !important;
  flex-direction: column;
}
.dashhead-subtitle {
  margin: 0;
  margin-bottom: 5px;
  font-size: 85%;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: normal;
}
.dashhead-title {
  margin: 0;
  font-weight: 300;
  font-weight: 700;
}
.dashhead-toolbar {
  display: flex !important;
  flex-direction: column;
}
.dashhead-toolbar-divider {
  width: 1px;
  background: $color_mercury_approx;
  height: 34px;
  margin-right: 15px;
}
.progress {
  &.is-xs {
    height: 4px;
    margin-bottom: 8px;
  }
  &.is-sm {
    height: 10px;
    margin-bottom: 15px;
  }
  &.is-md {
    height: 25px;
  }
  &.is-lg {
    height: 30px;
    margin-bottom: 25px;
  }
}
:-ms-fullscreen {
  body {
    position: fixed;
  }
  .app {
    height: 100%;
  }
  .app-container {
    overflow-y: auto;
  }
  .main-content {
    overflow-y: auto;
  }
}
.ps {
  overflow: hidden;
  position: relative;
}
.wizard {
  > {
    .content {
      min-height: 100px;
      > .body {
        position: relative;
      }
    }
    .steps {
      a {
        padding: 5px 8px;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 3px;
        &:hover {
          padding: 5px 8px;
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 3px;
        }
        &:active {
          padding: 5px 8px;
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 3px;
        }
      }
      > ul > li {
        float: none;
        width: 1%;
      }
    }
    .actions a {
      padding: 5px 8px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 3px;
      &:hover {
        padding: 5px 8px;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 3px;
      }
      &:active {
        padding: 5px 8px;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 3px;
      }
    }
  }
  ul > li {
    display: table-cell;
  }
}
.timeline {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  &:before {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    width: 2px;
    background: $black_10;
    left: 50%;
    z-index: 0;
  }
  &.is-right .timeline-icon {
    order: 1;
  }
}
.timeline-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 40px;
  z-index: 1;
  align-items: center;
}
.timeline-icon {
  width: 40px;
  height: 40px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
  color: $white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 0 0 40px;
  margin: 20px 0;
  &:not([class*="bg-"]) {
    background: $color_tundora_approx;
  }
}
.timeline-time {
  font-size: 16px;
  padding: 0;
  margin: 0;
}
.timeline-title {
  font-size: 13px;
  padding: 0;
  margin: 0;
  color: $color_tapa_approx;
}
.timeline-heading {
  margin: 0 auto 15px auto;
  text-align: center;
}
.timeline-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 15px;
  width: 100%;
}
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  overflow: hidden;
  outline: 0;
  background: $color_log_cabin_48_approx;
  opacity: .5;
}
.list-group-progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: $black_6;
}
.top-nav {
  position: relative;
  &.navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }
  > .nav > li > .nav-sub {
    border: 1px solid $color_mercury_approx;
    background: $white;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 2px 2px 0 $black_14, 0 1px 5px 0 $black_12, 0 3px 1px -2px $black_20;
  }
}
.side-nav {
  ul {
    ul {
      a {
        padding-left: 30px;
      }
      ul {
        a {
          padding-left: 40px;
        }
        ul a {
          padding-left: 50px;
        }
      }
    }
    a.active {
      color: $white;
    }
    &.nav a:hover i.fa {
      animation: fa-spin 2s infinite linear;
      &.arrow {
        animation: none;
      }
    }
  }
  a.active:before {
    position: absolute;
    content: '';
    border-width: 10px;
    border-style: solid;
    border-color: transparent $white transparent transparent;
    right: 0;
    top: 50%;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateY(-50%);
  }
  .nav > li > .nav-sub {
    position: relative;
    top: auto;
    left: auto;
  }
}
.nav-tools {
  float: right;
}
.navbar {
  border: 0;
  margin-bottom: 0;
}
.navbar-header {
  width: 100%;
  border: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 60px;
  .navbar-header-left {
    background: no-repeat;
  }
}
.navbar-header-left {
  width: 60px;
}
.navbar-header-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  > li {
    float: left;
    > a {
      padding: 20px 15px;
      > {
        .label {
          position: absolute;
          top: 8px;
          right: 5px;
          top: -9px;
        }
        .badge {
          position: absolute;
          top: 8px;
          right: 5px;
        }
        .point {
          top: -5px;
        }
      }
    }
  }
  > a {
    padding: 20px 15px;
    > {
      .label {
        position: absolute;
        top: 8px;
        right: 5px;
      }
      .badge {
        position: absolute;
        top: 8px;
        right: 5px;
      }
    }
  }
  .media {
    font-size: 12px;
    padding: 10px 15px;
  }
  .media-body {
    margin-left: 5px;
    p {
      margin: 0;
    }
  }
  .media-heading .pull-right {
    margin-top: 3px;
  }
  h5.media-heading {
    font-size: 14px;
    margin: 8px 0 10px;
  }
}
.navbar-form {
  margin: 0;
  padding: 13px;
  .form-control {
    width: 180px;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: width 0.3s ease-out;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    padding-left: 25px;
    &:focus {
      width: 300px;
      font-weight: 600;
      border-color: $color_curious_blue_approx;
    }
  }
  .icon-after-input {
    .icon a {
      color: $color_mine_shaft_approx;
    }
    .form-control:focus + .icon a {
      color: $color_curious_blue_approx;
    }
  }
}
.navbar-default-left {
  background: $black_4;
  width: 60px;
}
.point {
  position: absolute;
  width: 6px;
  height: 6px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
  top: 15px;
  right: 15px;
  .waves {
    top: -150%;
    left: -150%;
    width: 400%;
    height: 400%;
  }
}
.profile-pic {
  padding-top: 13px !important;
  padding-bottom: 11px !important;
  img {
    width: 36px;
    height: 36px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 50%;
  }
}
.logo {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
  img {
    max-height: 60px;
    max-width: 100%;
    max-height: 66px;
  }
  .logo-xs {
    text-decoration: none;
    flex: 1 1 0 !important;
    flex-basis: 0 !important;
    img {
      max-width: 60px;
    }
  }
  .logo-lg {
    text-decoration: none;
    flex: 1 1 0 !important;
    flex-basis: 0 !important;
  }
  .logo-text {
    font-weight: 500;
    color: $color_mine_shaft_approx;
    font-size: 18px;
    display: block;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    margin: 5px;
    text-align: center;
    padding: 10px 5px;
  }
}
.app-login {
  position: absolute;
  left: 50%;
  top: 50%;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate(-50%, -50%);
  min-width: 300px;
}
#totop {
  display: none;
  z-index: 1000;
  position: fixed;
  width: 40px;
  height: 40px;
  background-color: $color_mine_shaft_approx;
  background-color: $black_30;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: $white;
  top: auto;
  left: auto;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px;
}
.members {
  padding: 0;
  margin: 1em 0 0;
  list-style: none;
}
.member {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1em;
  + .member {
    padding-top: 1em;
    border-top: 1px dashed $color_bon_jour_approx;
  }
}
.member-media {
  margin-right: 1em;
}
.member-media-link {
  position: relative;
  &:focus {
    text-decoration: none;
    .member-media-img {
      opacity: 0.5;
    }
  }
  &:hover {
    text-decoration: none;
    .member-media-img {
      opacity: 0.5;
    }
  }
}
.member-media-img {
  max-width: 50px;
  height: auto;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.3s ease-out;
}
.member-status {
  position: absolute;
  width: 20px;
  height: 20px;
  background: $color_submarine_approx;
  border: 4px solid $color_athens_gray_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
  top: -15px;
  right: 0;
  &.is-active {
    background: $color_emerald_approx;
  }
}
.member-info {
  flex: 1 1 0;
  flex-basis: 0;
  margin-bottom: 0;
  &:last-child {
    margin-bottom: 0;
  }
}
.member-name {
  display: block;
  font-size: 14px;
  color: $color_chicago_approx;
  margin: 0.5em 0;
}
.member-skills {
  display: block;
  font-size: 14px;
  color: $color_silver_approx;
  color: $color_tapa_approx;
}
.tasks {
  padding: 0;
  margin: 1em 0 0;
  list-style: none;
}
.task {
  display: flex;
  align-items: flex-start;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 100%;
    border-left: 1px solid $color_bon_jour_approx;
    left: 10px;
    top: 1em;
  }
  &:last-child {
    &:before {
      height: 0;
    }
    .task-info {
      border-bottom-width: 0;
    }
  }
  + .task {
    padding-top: 1em;
  }
}
.task-media {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
  color: $white;
  width: 20px;
  font-size: 12px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  margin-right: 2em;
  z-index: 1;
}
.task-info {
  flex: 1 1 0;
  flex-basis: 0;
  padding-bottom: 1em;
  border-bottom: 1px solid $color_bon_jour_approx;
}
.task-info-link {
  color: $color_mine_shaft_approx;
  &:focus {
    text-decoration: none;
    border-bottom: 1px dashed $color_tundora_approx;
    color: $color_tundora_approx;
  }
  &:hover {
    text-decoration: none;
    border-bottom: 1px dashed $color_tundora_approx;
    color: $color_tundora_approx;
  }
}
.task-info-action {
  color: $color_silver_approx;
  color: $color_tapa_approx;
}
.task-info-time {
  display: block;
  color: $color_silver_approx;
  font-size: 12px;
  margin-top: 1em;
  color: $color_tapa_approx;
}
.loaderContainer {
  position: relative;
  background: $color_mine_shaft_50_approx;
}
.loader {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate(-50%, -50%) !important;
}
.col-xs-1 {
  flex-basis: auto !important;
}
.col-sm-1 {
  flex-basis: auto !important;
}
.col-md-1 {
  flex-basis: auto !important;
}
.col-lg-1 {
  flex-basis: auto !important;
}
.col-xs-2 {
  flex-basis: auto !important;
}
.col-sm-2 {
  flex-basis: auto !important;
}
.col-md-2 {
  flex-basis: auto !important;
}
.col-lg-2 {
  flex-basis: auto !important;
}
.col-xs-3 {
  flex-basis: auto !important;
}
.col-sm-3 {
  flex-basis: auto !important;
}
.col-md-3 {
  flex-basis: auto !important;
}
.col-lg-3 {
  flex-basis: auto !important;
}
.col-xs-4 {
  flex-basis: auto !important;
}
.col-sm-4 {
  flex-basis: auto !important;
}
.col-md-4 {
  flex-basis: auto !important;
}
.col-lg-4 {
  flex-basis: auto !important;
}
.col-xs-5 {
  flex-basis: auto !important;
}
.col-sm-5 {
  flex-basis: auto !important;
}
.col-md-5 {
  flex-basis: auto !important;
}
.col-lg-5 {
  flex-basis: auto !important;
}
.col-xs-6 {
  flex-basis: auto !important;
}
.col-sm-6 {
  flex-basis: auto !important;
}
.col-md-6 {
  flex-basis: auto !important;
}
.col-lg-6 {
  flex-basis: auto !important;
}
.col-xs-7 {
  flex-basis: auto !important;
}
.col-sm-7 {
  flex-basis: auto !important;
}
.col-md-7 {
  flex-basis: auto !important;
}
.col-lg-7 {
  flex-basis: auto !important;
}
.col-xs-8 {
  flex-basis: auto !important;
}
.col-sm-8 {
  flex-basis: auto !important;
}
.col-md-8 {
  flex-basis: auto !important;
}
.col-lg-8 {
  flex-basis: auto !important;
}
.col-xs-9 {
  flex-basis: auto !important;
}
.col-sm-9 {
  flex-basis: auto !important;
}
.col-md-9 {
  flex-basis: auto !important;
}
.col-lg-9 {
  flex-basis: auto !important;
}
.col-xs-10 {
  flex-basis: auto !important;
}
.col-sm-10 {
  flex-basis: auto !important;
}
.col-md-10 {
  flex-basis: auto !important;
}
.col-lg-10 {
  flex-basis: auto !important;
}
.col-xs-11 {
  flex-basis: auto !important;
}
.col-sm-11 {
  flex-basis: auto !important;
}
.col-md-11 {
  flex-basis: auto !important;
}
.col-lg-11 {
  flex-basis: auto !important;
}
.col-xs-12 {
  flex-basis: auto !important;
}
.col-sm-12 {
  flex-basis: auto !important;
}
.col-md-12 {
  flex-basis: auto !important;
}
.col-lg-12 {
  flex-basis: auto !important;
}
.u-sizeFit {
  flex-basis: auto !important;
}
.u-sizeFill {
  flex: 1 1 0 !important;
  flex-basis: 0 !important;
}
.u-sizeFillAlt {
  flex: 1 1 auto !important;
  flex-basis: auto !important;
  &.form-control {
    width: 1%;
  }
}
.u-sizeFull {
  width: 100% !important;
}
.u-posFit {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: auto !important;
  right: 0 !important;
  top: 0 !important;
}
.u-posAbsoluteCenter {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate(-50%, -50%) !important;
}
.u-posAbsolute {
  position: absolute !important;
}
.u-posFixedCenter {
  left: 50% !important;
  top: 50% !important;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate(-50%, -50%) !important;
  //Instead of the line below you could use @include backface-visibility($visibility)
  backface-visibility: hidden;
  position: fixed !important;
}
.u-posFullScreen {
  bottom: 0 !important;
  left: 0 !important;
  margin: auto !important;
  right: 0 !important;
  top: 0 !important;
  //Instead of the line below you could use @include backface-visibility($visibility)
  backface-visibility: hidden;
  position: fixed !important;
}
.u-posFixed {
  //Instead of the line below you could use @include backface-visibility($visibility)
  backface-visibility: hidden;
  position: fixed !important;
}
.u-posRelative {
  position: relative !important;
}
.u-posStatic {
  position: static !important;
}
.u-block {
  display: block !important;
}
.u-hidden {
  display: none !important;
}
.u-hiddenVisually {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}
.u-inline {
  display: inline !important;
}
.u-inlineBlock {
  display: inline-block !important;
  max-width: 100%;
}
.u-table {
  display: table !important;
}
.u-tableCell {
  display: table-cell !important;
}
.u-tableRow {
  display: table-row !important;
}
.b-0 {
  border: none !important;
}
.b-a {
  border: 1px solid $color_mercury_approx !important;
}
.b-t {
  border-top: 1px solid $color_mercury_approx !important;
}
.b-r {
  border-right: 1px solid $color_mercury_approx !important;
}
.b-b {
  border-bottom: 1px solid $color_mercury_approx !important;
}
.b-l {
  border-left: 1px solid $color_mercury_approx !important;
}
.b-h {
  border-top: 1px solid $color_mercury_approx !important;
  border-bottom: 1px solid $color_mercury_approx !important;
}
.b-v {
  border-right: 1px solid $color_mercury_approx !important;
  border-left: 1px solid $color_mercury_approx !important;
}
.b-w-1 {
  border-width: 1px !important;
}
.b-w-2 {
  border-width: 2px !important;
}
.b-w-3 {
  border-width: 3px !important;
}
.b-w-4 {
  border-width: 4px !important;
}
.b-w-5 {
  border-width: 5px !important;
}
.b-r-0 {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 !important;
}
.b-r-1 {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 1px !important;
}
.b-r-2 {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px !important;
}
.b-r-3 {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px !important;
}
.b-r-4 {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 4px !important;
}
.b-r-5 {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 5px !important;
}
.b-s-solid {
  border-style: solid !important;
}
.b-s-dashed {
  border-style: dashed !important;
}
.b-s-dotted {
  border-style: dotted !important;
}
.bg-transparent {
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
  &:focus {
    background-color: transparent !important;
  }
}
.bg-turquoise {
  background-color: $color_mountain_meadow_approx !important;
  color: $white;
}
.bg-emerland {
  background-color: $color_emerald_approx !important;
  color: $white;
}
.bg-peter-river {
  background-color: $color_curious_blue_approx !important;
  color: $white;
}
.bg-amethyst {
  background-color: $color_wisteria_approx !important;
  color: $white;
}
.bg-wet-asphalt {
  background-color: $color_pickled_bluewood_approx !important;
  color: $white;
}
.bg-green-sea {
  background-color: $color_niagara_approx !important;
  color: $white;
}
.bg-nephritis {
  background-color: $color_chateau_green_approx !important;
  color: $white;
}
.bg-belize-hole {
  background-color: $color_boston_blue_approx !important;
  color: $white;
}
.bg-wisteria {
  background-color: $color_studio_approx !important;
  color: $white;
}
.bg-midnight-blue {
  background-color: $color_pickled_bluewood_approx !important;
  color: $white;
}
.bg-sun-flower {
  background-color: $color_corn_approx !important;
  color: $white;
}
.bg-carrot {
  background-color: $color_zest_approx !important;
  color: $white;
}
.bg-alizarin {
  background-color: $color_cinnabar_approx !important;
  color: $white;
}
.bg-clouds {
  background-color: $color_athens_gray_approx !important;
  color: $color_mine_shaft_approx;
}
.bg-concrete {
  background-color: $color_pewter_approx !important;
  color: $color_mine_shaft_approx;
}
.bg-orange {
  background-color: $color_tree_poppy_approx !important;
  color: $white;
}
.bg-pumpkin {
  background-color: $color_tenn_approx !important;
  color: $white;
}
.bg-pomegranate {
  background-color: $color_flush_mahogany_approx !important;
  color: $white;
}
.bg-silver {
  background-color: $color_submarine_approx !important;
  color: $color_mine_shaft_approx;
}
.bg-asbestos {
  background-color: $color_oslo_gray_approx !important;
  color: $white;
}
.bg-white {
  background-color: $white !important;
  color: $color_mine_shaft_approx;
}
.bg-black {
  background-color: $color_mine_shaft_approx !important;
  color: $white;
}
.text-turquoise {
  color: $color_mountain_meadow_approx !important;
}
.text-emerland {
  color: $color_emerald_approx !important;
}
.text-peter-river {
  color: $color_curious_blue_approx !important;
}
.text-amethyst {
  color: $color_wisteria_approx !important;
}
.text-wet-asphalt {
  color: $color_pickled_bluewood_approx !important;
}
.text-green-sea {
  color: $color_niagara_approx !important;
}
.text-nephritis {
  color: $color_chateau_green_approx !important;
}
.text-belize-hole {
  color: $color_boston_blue_approx !important;
}
.text-wisteria {
  color: $color_studio_approx !important;
  color: $color_curious_blue_approx !important;
  font-size: 14px;
  padding: 10px 0 0;
}
.text-midnight-blue {
  color: $color_pickled_bluewood_approx !important;
}
.text-sun-flower {
  color: $color_corn_approx !important;
}
.text-carrot {
  color: $color_zest_approx !important;
}
.text-alizarin {
  color: $color_cinnabar_approx !important;
}
.text-clouds {
  color: $color_athens_gray_approx !important;
}
.text-concrete {
  color: $color_pewter_approx !important;
}
.text-orange {
  color: $color_tree_poppy_approx !important;
}
.text-pumpkin {
  color: $color_tenn_approx !important;
}
.text-pomegranate {
  color: $color_flush_mahogany_approx !important;
}
.text-silver {
  color: $color_submarine_approx !important;
}
.text-asbestos {
  color: $color_oslo_gray_approx !important;
  color: $color_curious_blue_approx !important;
  font-size: 14px;
  padding: 10px 0 0;
}
.text-white {
  color: $white !important;
}
.text-black {
  color: $color_mine_shaft_approx !important;
}
.b-turquoise {
  border-color: $color_mountain_meadow_approx !important;
}
.b-emerland {
  border-color: $color_emerald_approx !important;
}
.b-peter-river {
  border-color: $color_curious_blue_approx !important;
}
.b-amethyst {
  border-color: $color_wisteria_approx !important;
}
.b-wet-asphalt {
  border-color: $color_pickled_bluewood_approx !important;
}
.b-green-sea {
  border-color: $color_niagara_approx !important;
}
.b-nephritis {
  border-color: $color_chateau_green_approx !important;
}
.b-belize-hole {
  border-color: $color_boston_blue_approx !important;
}
.b-wisteria {
  border-color: $color_studio_approx !important;
}
.b-midnight-blue {
  border-color: $color_pickled_bluewood_approx !important;
}
.b-sun-flower {
  border-color: $color_corn_approx !important;
}
.b-carrot {
  border-color: $color_zest_approx !important;
}
.b-alizarin {
  border-color: $color_cinnabar_approx !important;
}
.b-clouds {
  border-color: $color_athens_gray_approx !important;
}
.b-concrete {
  border-color: $color_pewter_approx !important;
}
.b-orange {
  border-color: $color_tree_poppy_approx !important;
}
.b-pumpkin {
  border-color: $color_tenn_approx !important;
}
.b-pomegranate {
  border-color: $color_flush_mahogany_approx !important;
}
.b-silver {
  border-color: $color_submarine_approx !important;
}
.b-asbestos {
  border-color: $color_oslo_gray_approx !important;
}
.b-white {
  border-color: $white !important;
}
.b-black {
  border-color: $color_mine_shaft_approx !important;
}
.f-n {
  font-weight: normal;
}
.f-b {
  font-weight: bold;
}
.f-300 {
  font-weight: 300;
}
.f-400 {
  font-weight: 400;
}
.f-600 {
  font-weight: 600;
}
.f-700 {
  font-weight: 700;
}
.f-800 {
  font-weight: 800;
}
.f-1 {
  font-weight: 300;
  font-size: 96px;
}
.f-2 {
  font-weight: 300;
  font-size: 88px;
}
.f-3 {
  font-weight: 300;
  font-size: 72px;
}
.f-4 {
  font-weight: 300;
  font-size: 56px;
}
.f-5 {
  font-weight: 300;
  font-size: 40px;
}
.w-xs {
  width: 200px;
}
.w-sm {
  width: 300px;
  width: 280px;
}
.w-md {
  width: 400px;
}
.w-lg {
  width: 480px;
}
.w-xl {
  width: 600px;
}
.h-xs {
  height: 150px;
}
.h-sm {
  height: 225px;
}
.h-md {
  height: 300px;
}
.h-lg {
  height: 360px;
}
.h-xl {
  height: 450px;
}
.max-w-xs {
  max-width: 200px;
}
.max-w-sm {
  max-width: 300px;
}
.max-w-md {
  max-width: 400px;
}
.max-w-lg {
  max-width: 480px;
}
.max-w-xl {
  max-width: 600px;
}
.max-h-xs {
  max-height: 150px;
}
.max-h-sm {
  max-height: 225px;
}
.max-h-md {
  max-height: 300px;
}
.max-h-lg {
  max-height: 360px;
}
.max-h-xl {
  max-height: 450px;
}
.scr-a-a {
  overflow: auto;
}
.scr-x-a {
  overflow-x: auto;
}
.scr-y-a {
  overflow-y: auto;
}
.scr-a-v {
  overflow: visible;
}
.scr-x-v {
  overflow-x: visible;
}
.scr-y-v {
  overflow-y: visible;
}
.scr-a-h {
  overflow: hidden;
}
.scr-x-h {
  overflow-x: hidden;
}
.scr-y-h {
  overflow-y: hidden;
}
.scr-a-s {
  overflow: scroll;
}
.scr-h-s {
  overflow-x: scroll;
}
.scr-y-s {
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: $color_mountain_mist_approx;
  cursor: grab;
  &:hover {
    background: $color_fuscous_gray_approx;
  }
  &:active {
    background: $color_mine_shaft_approx;
  }
}
.no-shadow {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none !important;
  &:hover {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none !important;
  }
  &:active {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none !important;
  }
  &:focus {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none !important;
  }
  &.active {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none !important;
  }
}
.shadow-2dp {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 2px 2px 0 $black_14, 0 1px 5px 0 $black_12, 0 3px 1px -2px $black_20;
}
.shadow-3dp {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 3px 4px 0 $black_14, 0 1px 8px 0 $black_12, 0 3px 3px -2px $black_40;
}
.shadow-4dp {
}
.shadow-6dp {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 6px 10px 0 $black_14, 0 1px 18px 0 $black_12, 0 3px 5px -1px $black_40;
}
.shadow-8dp {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 8px 10px 1px $black_14, 0 3px 14px 2px $black_12, 0 5px 5px -3px $black_40;
}
.shadow-16dp {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 16px 24px 2px $black_14, 0 6px 30px 5px $black_12, 0 8px 10px -5px $black_40;
}
.shadow-24dp {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 9px 46px 8px $black_14, 0 24px 38px 3px $black_12, 0 11px 15px -7px $black_40;
}
.m-a-a {
  margin: auto;
}
.m-t-a {
  margin-top: auto;
}
.m-r-a {
  margin-right: auto;
}
.m-b-a {
  margin-bottom: auto;
}
.m-l-a {
  margin-left: auto;
}
.m-h-a {
  margin-right: auto;
  margin-left: auto;
}
.m-v-a {
  margin-top: auto;
  margin-bottom: auto;
}
.m-a-0 {
  margin: 0 !important;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-r-0 {
  margin-right: 0 !important;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.m-l-0 {
  margin-left: 0 !important;
}
.m-h-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.m-v-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.p-a-0 {
  padding: 0 !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.p-r-0 {
  padding-right: 0 !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-l-0 {
  padding-left: 0 !important;
}
.p-h-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.p-v-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.m-a-5 {
  margin: 5px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-h-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.m-v-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.p-a-5 {
  padding: 5px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-h-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.p-v-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.m-a-10 {
  margin: 10px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-h-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.m-v-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.p-a-10 {
  padding: 10px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-r-10 {
  padding-right: 10px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-h-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.p-v-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.m-a-15 {
  margin: 15px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.m-h-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.m-v-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.p-a-15 {
  padding: 15px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-r-15 {
  padding-right: 15px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.p-l-15 {
  padding-left: 15px !important;
}
.p-h-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.p-v-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.m-a-20 {
  margin: 20px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-h-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}
.m-v-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.p-a-20 {
  padding: 20px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-h-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.p-v-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.m-a-25 {
  margin: 25px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}
.m-r-25 {
  margin-right: 25px !important;
}
.m-b-25 {
  margin-bottom: 25px !important;
}
.m-l-25 {
  margin-left: 25px !important;
}
.m-h-25 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}
.m-v-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.p-a-25 {
  padding: 25px !important;
}
.p-t-25 {
  padding-top: 25px !important;
}
.p-r-25 {
  padding-right: 25px !important;
}
.p-b-25 {
  padding-bottom: 25px !important;
}
.p-l-25 {
  padding-left: 25px !important;
}
.p-h-25 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}
.p-v-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.m-a-30 {
  margin: 30px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-r-30 {
  margin-right: 30px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-l-30 {
  margin-left: 30px !important;
}
.m-h-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}
.m-v-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.p-a-30 {
  padding: 30px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-r-30 {
  padding-right: 30px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.p-h-30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}
.p-v-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.m-a-35 {
  margin: 35px !important;
}
.m-t-35 {
  margin-top: 35px !important;
}
.m-r-35 {
  margin-right: 35px !important;
}
.m-b-35 {
  margin-bottom: 35px !important;
}
.m-l-35 {
  margin-left: 35px !important;
}
.m-h-35 {
  margin-right: 35px !important;
  margin-left: 35px !important;
}
.m-v-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}
.p-a-35 {
  padding: 35px !important;
}
.p-t-35 {
  padding-top: 35px !important;
}
.p-r-35 {
  padding-right: 35px !important;
}
.p-b-35 {
  padding-bottom: 35px !important;
}
.p-l-35 {
  padding-left: 35px !important;
}
.p-h-35 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}
.p-v-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.m-a-40 {
  margin: 40px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-r-40 {
  margin-right: 40px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-l-40 {
  margin-left: 40px !important;
}
.m-h-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}
.m-v-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.p-a-40 {
  padding: 40px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-r-40 {
  padding-right: 40px !important;
}
.p-b-40 {
  padding-bottom: 40px !important;
}
.p-l-40 {
  padding-left: 40px !important;
}
.p-h-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.p-v-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.m-a-45 {
  margin: 45px !important;
}
.m-t-45 {
  margin-top: 45px !important;
}
.m-r-45 {
  margin-right: 45px !important;
}
.m-b-45 {
  margin-bottom: 45px !important;
}
.m-l-45 {
  margin-left: 45px !important;
}
.m-h-45 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}
.m-v-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}
.p-a-45 {
  padding: 45px !important;
}
.p-t-45 {
  padding-top: 45px !important;
}
.p-r-45 {
  padding-right: 45px !important;
}
.p-b-45 {
  padding-bottom: 45px !important;
}
.p-l-45 {
  padding-left: 45px !important;
}
.p-h-45 {
  padding-right: 45px !important;
  padding-left: 45px !important;
}
.p-v-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.m-a-50 {
  margin: 50px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}
.m-r-50 {
  margin-right: 50px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}
.m-l-50 {
  margin-left: 50px !important;
}
.m-h-50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}
.m-v-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.p-a-50 {
  padding: 50px !important;
}
.p-t-50 {
  padding-top: 50px !important;
}
.p-r-50 {
  padding-right: 50px !important;
}
.p-b-50 {
  padding-bottom: 50px !important;
}
.p-l-50 {
  padding-left: 50px !important;
}
.p-h-50 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}
.p-v-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.m-a-55 {
  margin: 55px !important;
}
.m-t-55 {
  margin-top: 55px !important;
}
.m-r-55 {
  margin-right: 55px !important;
}
.m-b-55 {
  margin-bottom: 55px !important;
}
.m-l-55 {
  margin-left: 55px !important;
}
.m-h-55 {
  margin-right: 55px !important;
  margin-left: 55px !important;
}
.m-v-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}
.p-a-55 {
  padding: 55px !important;
}
.p-t-55 {
  padding-top: 55px !important;
}
.p-r-55 {
  padding-right: 55px !important;
}
.p-b-55 {
  padding-bottom: 55px !important;
}
.p-l-55 {
  padding-left: 55px !important;
}
.p-h-55 {
  padding-right: 55px !important;
  padding-left: 55px !important;
}
.p-v-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}
.m-a-60 {
  margin: 60px !important;
}
.m-t-60 {
  margin-top: 60px !important;
}
.m-r-60 {
  margin-right: 60px !important;
}
.m-b-60 {
  margin-bottom: 60px !important;
}
.m-l-60 {
  margin-left: 60px !important;
}
.m-h-60 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}
.m-v-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.p-a-60 {
  padding: 60px !important;
}
.p-t-60 {
  padding-top: 60px !important;
}
.p-r-60 {
  padding-right: 60px !important;
}
.p-b-60 {
  padding-bottom: 60px !important;
}
.p-l-60 {
  padding-left: 60px !important;
}
.p-h-60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}
.p-v-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.m-a-65 {
  margin: 65px !important;
}
.m-t-65 {
  margin-top: 65px !important;
}
.m-r-65 {
  margin-right: 65px !important;
}
.m-b-65 {
  margin-bottom: 65px !important;
}
.m-l-65 {
  margin-left: 65px !important;
}
.m-h-65 {
  margin-right: 65px !important;
  margin-left: 65px !important;
}
.m-v-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}
.p-a-65 {
  padding: 65px !important;
}
.p-t-65 {
  padding-top: 65px !important;
}
.p-r-65 {
  padding-right: 65px !important;
}
.p-b-65 {
  padding-bottom: 65px !important;
}
.p-l-65 {
  padding-left: 65px !important;
}
.p-h-65 {
  padding-right: 65px !important;
  padding-left: 65px !important;
}
.p-v-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}
.m-a-70 {
  margin: 70px !important;
}
.m-t-70 {
  margin-top: 70px !important;
}
.m-r-70 {
  margin-right: 70px !important;
}
.m-b-70 {
  margin-bottom: 70px !important;
}
.m-l-70 {
  margin-left: 70px !important;
}
.m-h-70 {
  margin-right: 70px !important;
  margin-left: 70px !important;
}
.m-v-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}
.p-a-70 {
  padding: 70px !important;
}
.p-t-70 {
  padding-top: 70px !important;
}
.p-r-70 {
  padding-right: 70px !important;
}
.p-b-70 {
  padding-bottom: 70px !important;
}
.p-l-70 {
  padding-left: 70px !important;
}
.p-h-70 {
  padding-right: 70px !important;
  padding-left: 70px !important;
}
.p-v-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}
.m-a-75 {
  margin: 75px !important;
}
.m-t-75 {
  margin-top: 75px !important;
}
.m-r-75 {
  margin-right: 75px !important;
}
.m-b-75 {
  margin-bottom: 75px !important;
}
.m-l-75 {
  margin-left: 75px !important;
}
.m-h-75 {
  margin-right: 75px !important;
  margin-left: 75px !important;
}
.m-v-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}
.p-a-75 {
  padding: 75px !important;
}
.p-t-75 {
  padding-top: 75px !important;
}
.p-r-75 {
  padding-right: 75px !important;
}
.p-b-75 {
  padding-bottom: 75px !important;
}
.p-l-75 {
  padding-left: 75px !important;
}
.p-h-75 {
  padding-right: 75px !important;
  padding-left: 75px !important;
}
.p-v-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}
.m-a-80 {
  margin: 80px !important;
}
.m-t-80 {
  margin-top: 80px !important;
}
.m-r-80 {
  margin-right: 80px !important;
}
.m-b-80 {
  margin-bottom: 80px !important;
}
.m-l-80 {
  margin-left: 80px !important;
}
.m-h-80 {
  margin-right: 80px !important;
  margin-left: 80px !important;
}
.m-v-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}
.p-a-80 {
  padding: 80px !important;
}
.p-t-80 {
  padding-top: 80px !important;
}
.p-r-80 {
  padding-right: 80px !important;
}
.p-b-80 {
  padding-bottom: 80px !important;
}
.p-l-80 {
  padding-left: 80px !important;
}
.p-h-80 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}
.p-v-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.m-a-85 {
  margin: 85px !important;
}
.m-t-85 {
  margin-top: 85px !important;
}
.m-r-85 {
  margin-right: 85px !important;
}
.m-b-85 {
  margin-bottom: 85px !important;
}
.m-l-85 {
  margin-left: 85px !important;
}
.m-h-85 {
  margin-right: 85px !important;
  margin-left: 85px !important;
}
.m-v-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}
.p-a-85 {
  padding: 85px !important;
}
.p-t-85 {
  padding-top: 85px !important;
}
.p-r-85 {
  padding-right: 85px !important;
}
.p-b-85 {
  padding-bottom: 85px !important;
}
.p-l-85 {
  padding-left: 85px !important;
}
.p-h-85 {
  padding-right: 85px !important;
  padding-left: 85px !important;
}
.p-v-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}
.m-a-90 {
  margin: 90px !important;
}
.m-t-90 {
  margin-top: 90px !important;
}
.m-r-90 {
  margin-right: 90px !important;
}
.m-b-90 {
  margin-bottom: 90px !important;
}
.m-l-90 {
  margin-left: 90px !important;
}
.m-h-90 {
  margin-right: 90px !important;
  margin-left: 90px !important;
}
.m-v-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}
.p-a-90 {
  padding: 90px !important;
}
.p-t-90 {
  padding-top: 90px !important;
}
.p-r-90 {
  padding-right: 90px !important;
}
.p-b-90 {
  padding-bottom: 90px !important;
}
.p-l-90 {
  padding-left: 90px !important;
}
.p-h-90 {
  padding-right: 90px !important;
  padding-left: 90px !important;
}
.p-v-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}
.m-a-95 {
  margin: 95px !important;
}
.m-t-95 {
  margin-top: 95px !important;
}
.m-r-95 {
  margin-right: 95px !important;
}
.m-b-95 {
  margin-bottom: 95px !important;
}
.m-l-95 {
  margin-left: 95px !important;
}
.m-h-95 {
  margin-right: 95px !important;
  margin-left: 95px !important;
}
.m-v-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}
.p-a-95 {
  padding: 95px !important;
}
.p-t-95 {
  padding-top: 95px !important;
}
.p-r-95 {
  padding-right: 95px !important;
}
.p-b-95 {
  padding-bottom: 95px !important;
}
.p-l-95 {
  padding-left: 95px !important;
}
.p-h-95 {
  padding-right: 95px !important;
  padding-left: 95px !important;
}
.p-v-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}
.m-a-100 {
  margin: 100px !important;
}
.m-t-100 {
  margin-top: 100px !important;
}
.m-r-100 {
  margin-right: 100px !important;
}
.m-b-100 {
  margin-bottom: 100px !important;
}
.m-l-100 {
  margin-left: 100px !important;
}
.m-h-100 {
  margin-right: 100px !important;
  margin-left: 100px !important;
}
.m-v-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}
.p-a-100 {
  padding: 100px !important;
}
.p-t-100 {
  padding-top: 100px !important;
}
.p-r-100 {
  padding-right: 100px !important;
}
.p-b-100 {
  padding-bottom: 100px !important;
}
.p-l-100 {
  padding-left: 100px !important;
}
.p-h-100 {
  padding-right: 100px !important;
  padding-left: 100px !important;
}
.p-v-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
.waves {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: inherit;
  animation-name: waves;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@-webkit-keyframes waves {
  0% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.5);
    opacity: 0.0;
  }
  25% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.5);
    opacity: 0.1;
  }
  50% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.6);
    opacity: 0.3;
  }
  75% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.75);
    opacity: 0.5;
  }
  100% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(1);
    opacity: 0.0;
  }
}
@keyframes waves {
  0% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.5);
    opacity: 0.0;
  }
  25% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.5);
    opacity: 0.1;
  }
  50% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.6);
    opacity: 0.3;
  }
  75% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.75);
    opacity: 0.5;
  }
  100% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(1);
    opacity: 0.0;
  }
}
.z-0 {
  z-index: 0 !important;
}
.z-1 {
  z-index: 1 !important;
}
.z-2 {
  z-index: 2 !important;
}
.z-3 {
  z-index: 3 !important;
}
.z-4 {
  z-index: 4 !important;
}
.z-5 {
  z-index: 5 !important;
}
.z-999 {
  z-index: 999 !important;
}
.z-9999 {
  z-index: 9999 !important;
}
.z-max {
  z-index: 2147483647 !important;
}
.z-inherit {
  z-index: inherit !important;
}
.z-initial {
  z-index: initial !important;
}
.z-unset {
  z-index: unset !important;
}
.logo-lg {
  padding: 0 10px;
}
.hidden-md {
  display: none;
}
.label-notify {
  background-color: $color_tree_poppy_approx;
  padding: 0 8px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px;
  color: $white_80;
  font-size: 12px;
  &.b-orange {
    background-color: $color_tree_poppy_approx;
  }
}
.dropdown-toggle::after {
  vertical-align: .125em;
  border-top: .4em solid $color_tree_poppy_approx;
  border-right: .4em solid transparent;
  border-left: .4em solid transparent;
}
.label-danger {
  background-color: $color_cinnabar_approx;
  padding: 0 8px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px;
  color: $white_80;
  font-size: 12px;
}
aside {
  &.app-side {
    nav.side-nav {
    }
    .nav > li {
      width: 100%;
      > a {
        width: 240px;
        position: relative;
        display: block;
        padding: 10px 15px;
      }
    }
    ul.nav.nav-sub > li {
      a {
        padding-left: 30px;
      }
      ul.nav.nav-sub li {
        a {
          padding-left: 50px;
        }
        ul.nav.nav-sub li a {
          padding-left: 70px;
        }
      }
    }
  }
  .user-panel li.dropdown ul.dropdown-menu.pull-right.show {
    padding: 10px;
  }
}
.side-footer {
  background-color: $color_shark_approx;
  border-top: 1px solid $white_20;
  p {
    margin: 10px 0 5px;
  }
}
.navbar-default {
  background: $alabaster_99;
  .navbar-header-nav > li > a {
    color: $color_mine_shaft_approx;
    &:hover {
      color: $black;
      background-color: transparent;
    }
    &:focus {
      color: $black;
      background-color: transparent;
    }
  }
}
.small {
  padding-top: 5px;
}
.progress-bar {
  background-color: $color_azure_radiance_approx;
}
.progress-bar-success {
  background-color: $color_tree_poppy_approx;
}
.app-footer {
  background-color: $color_shark_approx;
}
@media screen and(max-width: 991px) {
  .navbar-header-nav {
    > a {
      padding: 20px 10px;
    }
    > li > a {
      padding: 20px 10px;
    }
  }
  .logged-user-display-name {
    display: none;
  }
}
@media screen and(max-width: 767px) {
  .hidden-md {
    display: block;
  }
  .visible-md {
    display: none;
  }
  .navbar-header-nav {
    > a {
      padding: 20px 15px;
    }
    > li > a {
      padding: 20px 15px;
    }
  }
  .navbar-header .navbar-header-left {
    width: 120px;
  }
  .logo .logo-xs img {
    max-width: 120px;
    padding: 0 10px;
  }
}
@media screen and(max-width: 480px) {
  .navbar-header-nav {
    > a {
      padding: 20px 10px;
    }
    > li > a {
      padding: 20px 10px;
    }
  }
}
@media screen and(max-width: 420px) {
  .w-sm {
    width: 270px;
  }
  .navbar-header .navbar-header-left {
    width: 100%;
    text-align: center;
    padding: 10px;
  }
  ul.navbar-header-nav li.dropdown ul.dropdown-menu.pull-right.show {
    padding: 5px;
    min-width: 120px;
  }
}
@media screen and(max-width: 375px) {
  .w-sm {
    width: 250px;
  }
  ul.navbar-header-nav li.dropdown ul.dropdown-menu.w-sm.pull-right.show {
    right: -70px;
    left: auto;
  }
}
.user-image img {
  max-width: 40px;
  height: auto;
  border: 1px solid $white_70;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 5px;
}
.form-group-sm .form-control {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}
.form-group-lg .form-control {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}
.input-group-lg > {
  .form-control {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
  }
  .input-group-addon {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
  }
  .input-group-btn > .btn {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
  }
}
.input-group-sm > {
  .form-control {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
  }
  .input-group-addon {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
  }
  .input-group-btn > .btn {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
  }
}
.input-group-btn:not(:first-child):not(:last-child) {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}
input[type="checkbox"].hidden {
  + .fa {
    width: 1.28571429em;
    text-align: center;
    &:before {
      content: "\f096";
    }
  }
  &:checked + .fa:before {
    content: "\f14a";
  }
}
input[type="radio"].hidden {
  + .fa {
    width: 1.28571429em;
    text-align: center;
    &:before {
      content: "\f10c";
    }
  }
  &:checked + .fa:before {
    content: "\f192";
  }
}
fieldset[disabled] .btn-line {
  &.btn-default {
    background-color: $color_gallery_approx;
    border: 1px solid $color_alto_approx;
    color: $color_celeste_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 1px $white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:focus {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &.active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }
  &.btn-primary {
    background-color: $color_gallery_approx;
    border: 1px solid $color_alto_approx;
    color: $color_celeste_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 1px $white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:focus {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &.active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }
  &.btn-warning {
    background-color: $color_gallery_approx;
    border: 1px solid $color_alto_approx;
    color: $color_celeste_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 1px $white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:focus {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &.active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }
  &.btn-danger {
    background-color: $color_gallery_approx;
    border: 1px solid $color_alto_approx;
    color: $color_celeste_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 1px $white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:focus {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &.active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }
  &.btn-success {
    background-color: $color_gallery_approx;
    border: 1px solid $color_alto_approx;
    color: $color_celeste_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 1px $white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:focus {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &.active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }
  &.btn-info {
    background-color: $color_gallery_approx;
    border: 1px solid $color_alto_approx;
    color: $color_celeste_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 1px $white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:focus {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &:active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    &.active {
      background-color: $color_gallery_approx;
      border: 1px solid $color_alto_approx;
      color: $color_celeste_approx;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 1px $white;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }
}
.nav-inline .nav-header {
  margin: 0;
}
header.text-white .box-tools a {
  color: $alabaster;
  &:hover {
    color: $white;
  }
  &:focus {
    color: $white;
  }
}
.box-heading.text-white .box-tools a {
  color: $alabaster;
  &:hover {
    color: $white;
  }
  &:focus {
    color: $white;
  }
}
.dropdown-menu.ps {
  position: absolute;
}
.tabcontrol ul {
  width: 100%;
  > li {
    display: table-cell;
  }
}
.metismenu .active > a > .nav-tools .fa.arrow:before {
  content: "\f107";
}

.side-mini .navbar-default-left {
  width: 60px;
}
.canvas.is-fixed {
  z-index: 1031;
}
.ql-tooltip.ql-editing {
  z-index: 99999;
}
[class*="b-s-"] .waves {
  border-style: inherit;
}
[class*="b-w-"] .waves {
  border-width: inherit;
}
ul.navbar-header-nav li.dropdown ul.dropdown-menu.pull-right.show {
  min-width: 140px;
  padding: 10px;
  right: 0;
  left: auto;
}
nav.nav .fa {
  &.fa-bars {
    color: $color_azure_radiance_approx;
  }
  &.fa-bars::before {
    font-size: 28px;
  }
}
#footer.footer {
  padding: 10px 0 0;
}
.footer .copyright {
  color: $white;
  font-size: 14px;
  a {
    color: $white;
    font-size: 14px;
  }
}
.container-fluid h4 {
  font-weight: 700;
}