.metismenu {
  li {
    display: block;
    width: 100% !important;
  }
}
.card {
  .card-body {
    padding: 20px;

    .btn {
      margin-top: 5px;
    }
  }
}
